<template>
	<div class="aboutUs">
		<div class="topBox">
			<img src="../../assets/img.png" alt="">
			<p>名字</p>
			<p>提供河南各地特价美食，美旅</p>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {

			}
		}
	}
</script>

<style  lang="less" scoped>
	@import "~@/assets/style/preset.less";
	.aboutUs{
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		.topBox{
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			font-size: 0.25rem;
			img{
				width: 160px;
				height: 160px;
			}
		}
	}
</style>

